import { render, staticRenderFns } from "./index.vue?vue&type=template&id=238d933e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RoundButton: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/RoundButton/index.vue').default,AgencyExcursionCard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/AgencyExcursionCard/index.vue').default,PendingButton: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/PendingButton/index.vue').default,Button: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Button/index.vue').default,Section: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Section/index.vue').default})
