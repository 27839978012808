//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PaPrivacyPolicy',
  components: {
    Section: () => import('@/components/common/Section')
  },
  layout: 'documentation',
  data () {
    return {
      document: null
    }
  },
  computed: {
    title () {
      return this?.document?.title
    },
    description () {
      return this?.document?.description
    }
  },
  async mounted () {
    await this.$axios.$get(`/api/documents/pa-privacy-policy?lang=${this.$i18n.locale}`)
      .then(resolve => this.document = resolve.data)
      .catch(() => this.document = {})
  }
}
