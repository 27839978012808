import { render, staticRenderFns } from "./index.vue?vue&type=template&id=89d1b8f2&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RouteCard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/RouteCard/index.vue').default,Empty: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Empty/index.vue').default})
