import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0900d924&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YoutubeVideo: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/YoutubeVideo/index.vue').default,RutubeVideo: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/RutubeVideo/index.vue').default,VKVideo: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/VKVideo/index.vue').default})
