import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=5cf63567&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RoundButton: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/RoundButton/index.vue').default,ExcursionCard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/ExcursionCard/index.vue').default,RouteCard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/RouteCard/index.vue').default,PlaceCard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/PlaceCard/index.vue').default,EventCard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/EventCard/index.vue').default,EmptyEntity: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/EmptyEntity/index.vue').default})
