import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2140b8c4&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PartnerCard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/PartnerCard/index.vue').default,TestSlider: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/TestSlider/index.vue').default,Section: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Section/index.vue').default})
