import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_749cb8b7 from 'nuxt_plugin_plugin_749cb8b7' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_03662111 from 'nuxt_plugin_sentryserver_03662111' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_c4a6a0ee from 'nuxt_plugin_sentryclient_c4a6a0ee' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_pluginutils_4346338e from 'nuxt_plugin_pluginutils_4346338e' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_40881559 from 'nuxt_plugin_pluginrouting_40881559' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_324b7cb6 from 'nuxt_plugin_pluginmain_324b7cb6' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_e3860366 from 'nuxt_plugin_axios_e3860366' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_4edb3816 from 'nuxt_plugin_cookieuniversalnuxt_4edb3816' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_yandexmetrikaplugin660b2008_1b530e8e from 'nuxt_plugin_yandexmetrikaplugin660b2008_1b530e8e' // Source: ./yandex-metrika.plugin.660b2008.js (mode: 'client')
import nuxt_plugin_moment_480e5ceb from 'nuxt_plugin_moment_480e5ceb' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_router_24a151fc from 'nuxt_plugin_router_24a151fc' // Source: ./router.js (mode: 'all')
import nuxt_plugin_cardfromentity_1de59e86 from 'nuxt_plugin_cardfromentity_1de59e86' // Source: ../plugins/common/card-from-entity.ts (mode: 'all')
import nuxt_plugin_categoriestostring_7eafe846 from 'nuxt_plugin_categoriestostring_7eafe846' // Source: ../plugins/common/categories-to-string.ts (mode: 'all')
import nuxt_plugin_checkviewportlanguage_06b4df71 from 'nuxt_plugin_checkviewportlanguage_06b4df71' // Source: ../plugins/common/check-viewport-language.ts (mode: 'all')
import nuxt_plugin_closepopupstorycard_459b9931 from 'nuxt_plugin_closepopupstorycard_459b9931' // Source: ../plugins/common/close-popup-story-card.ts (mode: 'all')
import nuxt_plugin_currentlocale_56392be1 from 'nuxt_plugin_currentlocale_56392be1' // Source: ../plugins/common/current-locale.ts (mode: 'all')
import nuxt_plugin_datetimepickerclient_665306aa from 'nuxt_plugin_datetimepickerclient_665306aa' // Source: ../plugins/common/date-time-picker.client.js (mode: 'client')
import nuxt_plugin_defaultcityid_4176f3fa from 'nuxt_plugin_defaultcityid_4176f3fa' // Source: ../plugins/common/default-city-id.ts (mode: 'all')
import nuxt_plugin_defaultlocale_308eeaa9 from 'nuxt_plugin_defaultlocale_308eeaa9' // Source: ../plugins/common/default-locale.ts (mode: 'all')
import nuxt_plugin_directivesclient_c5c00a00 from 'nuxt_plugin_directivesclient_c5c00a00' // Source: ../plugins/common/directives.client.js (mode: 'client')
import nuxt_plugin_distance_3c19c804 from 'nuxt_plugin_distance_3c19c804' // Source: ../plugins/common/distance.js (mode: 'all')
import nuxt_plugin_duration_4acbae05 from 'nuxt_plugin_duration_4acbae05' // Source: ../plugins/common/duration.js (mode: 'all')
import nuxt_plugin_editorclient_22772bae from 'nuxt_plugin_editorclient_22772bae' // Source: ../plugins/common/editor.client.js (mode: 'client')
import nuxt_plugin_entitysingle_4315e399 from 'nuxt_plugin_entitysingle_4315e399' // Source: ../plugins/common/entity-single.ts (mode: 'all')
import nuxt_plugin_galleryframewidth_b8d8cf00 from 'nuxt_plugin_galleryframewidth_b8d8cf00' // Source: ../plugins/common/gallery-frame-width.ts (mode: 'all')
import nuxt_plugin_galleryindent_9cdf6230 from 'nuxt_plugin_galleryindent_9cdf6230' // Source: ../plugins/common/gallery-indent.ts (mode: 'all')
import nuxt_plugin_handlescrollto_3752db7a from 'nuxt_plugin_handlescrollto_3752db7a' // Source: ../plugins/common/handle-scroll-to.js (mode: 'all')
import nuxt_plugin_headlinkclient_3f453d07 from 'nuxt_plugin_headlinkclient_3f453d07' // Source: ../plugins/common/head-link.client.js (mode: 'client')
import nuxt_plugin_i18n_3c15c38a from 'nuxt_plugin_i18n_3c15c38a' // Source: ../plugins/common/i18n.js (mode: 'all')
import nuxt_plugin_instantreplacequery_566452b2 from 'nuxt_plugin_instantreplacequery_566452b2' // Source: ../plugins/common/instant-replace-query.js (mode: 'all')
import nuxt_plugin_isemail_737b3976 from 'nuxt_plugin_isemail_737b3976' // Source: ../plugins/common/is-email.ts (mode: 'all')
import nuxt_plugin_isemptyarray_4a8dfb4e from 'nuxt_plugin_isemptyarray_4a8dfb4e' // Source: ../plugins/common/is-empty-array.ts (mode: 'all')
import nuxt_plugin_isemptyobject_789cdc09 from 'nuxt_plugin_isemptyobject_789cdc09' // Source: ../plugins/common/is-empty-object.ts (mode: 'all')
import nuxt_plugin_isexternallink_7089b154 from 'nuxt_plugin_isexternallink_7089b154' // Source: ../plugins/common/is-external-link.ts (mode: 'all')
import nuxt_plugin_isphoneorempty_e9645074 from 'nuxt_plugin_isphoneorempty_e9645074' // Source: ../plugins/common/is-phone-or-empty.ts (mode: 'all')
import nuxt_plugin_isphone_0c5fc338 from 'nuxt_plugin_isphone_0c5fc338' // Source: ../plugins/common/is-phone.ts (mode: 'all')
import nuxt_plugin_isrutubevideo_d33894da from 'nuxt_plugin_isrutubevideo_d33894da' // Source: ../plugins/common/is-rutube-video.ts (mode: 'all')
import nuxt_plugin_issite_1c550281 from 'nuxt_plugin_issite_1c550281' // Source: ../plugins/common/is-site.ts (mode: 'all')
import nuxt_plugin_isvkvideo_deefe936 from 'nuxt_plugin_isvkvideo_deefe936' // Source: ../plugins/common/is-vk-video.ts (mode: 'all')
import nuxt_plugin_isyoutubevideo_fbc46abe from 'nuxt_plugin_isyoutubevideo_fbc46abe' // Source: ../plugins/common/is-youtube-video.ts (mode: 'all')
import nuxt_plugin_login_97c3df7c from 'nuxt_plugin_login_97c3df7c' // Source: ../plugins/common/login.ts (mode: 'all')
import nuxt_plugin_logout_4b3b07f6 from 'nuxt_plugin_logout_4b3b07f6' // Source: ../plugins/common/logout.ts (mode: 'all')
import nuxt_plugin_mapchangeroute_17b4d23c from 'nuxt_plugin_mapchangeroute_17b4d23c' // Source: ../plugins/common/map-change-route.js (mode: 'all')
import nuxt_plugin_maskapatternemailclient_27aa0107 from 'nuxt_plugin_maskapatternemailclient_27aa0107' // Source: ../plugins/common/maska-pattern-email.client.js (mode: 'client')
import nuxt_plugin_maskapatternsiteclient_7281a838 from 'nuxt_plugin_maskapatternsiteclient_7281a838' // Source: ../plugins/common/maska-pattern-site.client.js (mode: 'client')
import nuxt_plugin_maskapatterntelclient_7d576206 from 'nuxt_plugin_maskapatterntelclient_7d576206' // Source: ../plugins/common/maska-pattern-tel.client.js (mode: 'client')
import nuxt_plugin_maskaclient_4bfa23b5 from 'nuxt_plugin_maskaclient_4bfa23b5' // Source: ../plugins/common/maska.client.js (mode: 'client')
import nuxt_plugin_mediaswitch_abf9741c from 'nuxt_plugin_mediaswitch_abf9741c' // Source: ../plugins/common/media-switch.ts (mode: 'all')
import nuxt_plugin_openpopupaddplaceclient_506b419a from 'nuxt_plugin_openpopupaddplaceclient_506b419a' // Source: ../plugins/common/open-popup-add-place.client.js (mode: 'client')
import nuxt_plugin_openpopuporder_286f1f25 from 'nuxt_plugin_openpopuporder_286f1f25' // Source: ../plugins/common/open-popup-order.ts (mode: 'all')
import nuxt_plugin_openpupupforprotectedareas_7117cab6 from 'nuxt_plugin_openpupupforprotectedareas_7117cab6' // Source: ../plugins/common/open-pupup-for-protected-areas.ts (mode: 'all')
import nuxt_plugin_prefixlocale_7bffc836 from 'nuxt_plugin_prefixlocale_7bffc836' // Source: ../plugins/common/prefix-locale.ts (mode: 'all')
import nuxt_plugin_randomstring_6033f010 from 'nuxt_plugin_randomstring_6033f010' // Source: ../plugins/common/random-string.ts (mode: 'all')
import nuxt_plugin_scrolltovalidationclient_693296c7 from 'nuxt_plugin_scrolltovalidationclient_693296c7' // Source: ../plugins/common/scroll-to-validation.client.js (mode: 'client')
import nuxt_plugin_scrollbarclient_42b52eb4 from 'nuxt_plugin_scrollbarclient_42b52eb4' // Source: ../plugins/common/scrollbar.client.js (mode: 'client')
import nuxt_plugin_seoimage_7dc25980 from 'nuxt_plugin_seoimage_7dc25980' // Source: ../plugins/common/seo-image.ts (mode: 'all')
import nuxt_plugin_seo_23915d18 from 'nuxt_plugin_seo_23915d18' // Source: ../plugins/common/seo.js (mode: 'all')
import nuxt_plugin_showcityselector_15da65fe from 'nuxt_plugin_showcityselector_15da65fe' // Source: ../plugins/common/show-city-selector.ts (mode: 'all')
import nuxt_plugin_socials_2091b465 from 'nuxt_plugin_socials_2091b465' // Source: ../plugins/common/socials.ts (mode: 'all')
import nuxt_plugin_theme_7da66cac from 'nuxt_plugin_theme_7da66cac' // Source: ../plugins/common/theme.js (mode: 'all')
import nuxt_plugin_vuetouchclient_404d7438 from 'nuxt_plugin_vuetouchclient_404d7438' // Source: ../plugins/common/vue-touch.client.js (mode: 'client')
import nuxt_plugin_ymappluginclient_14d0733d from 'nuxt_plugin_ymappluginclient_14d0733d' // Source: ../plugins/common/ymap-plugin.client.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"},{"name":"yandex-verification","content":"5aa925a641c954a2"},{"name":"version","content":"1.5.16"}],"link":[{"rel":"icon","type":"image\u002Fpng","href":"\u002Ffavicon.png"},{"href":"https:\u002F\u002Fmc.yandex.ru\u002Fmetrika\u002Ftag.js","rel":"preload","as":"script"}],"script":[{"src":"https:\u002F\u002Fapi-maps.yandex.ru\u002F2.1\u002F?apikey=3ce74472-614d-48ae-be3b-43d1739a0368&lang=ru_RU"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_749cb8b7 === 'function') {
    await nuxt_plugin_plugin_749cb8b7(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_03662111 === 'function') {
    await nuxt_plugin_sentryserver_03662111(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_c4a6a0ee === 'function') {
    await nuxt_plugin_sentryclient_c4a6a0ee(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_4346338e === 'function') {
    await nuxt_plugin_pluginutils_4346338e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_40881559 === 'function') {
    await nuxt_plugin_pluginrouting_40881559(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_324b7cb6 === 'function') {
    await nuxt_plugin_pluginmain_324b7cb6(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_e3860366 === 'function') {
    await nuxt_plugin_axios_e3860366(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_4edb3816 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_4edb3816(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_yandexmetrikaplugin660b2008_1b530e8e === 'function') {
    await nuxt_plugin_yandexmetrikaplugin660b2008_1b530e8e(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_480e5ceb === 'function') {
    await nuxt_plugin_moment_480e5ceb(app.context, inject)
  }

  if (typeof nuxt_plugin_router_24a151fc === 'function') {
    await nuxt_plugin_router_24a151fc(app.context, inject)
  }

  if (typeof nuxt_plugin_cardfromentity_1de59e86 === 'function') {
    await nuxt_plugin_cardfromentity_1de59e86(app.context, inject)
  }

  if (typeof nuxt_plugin_categoriestostring_7eafe846 === 'function') {
    await nuxt_plugin_categoriestostring_7eafe846(app.context, inject)
  }

  if (typeof nuxt_plugin_checkviewportlanguage_06b4df71 === 'function') {
    await nuxt_plugin_checkviewportlanguage_06b4df71(app.context, inject)
  }

  if (typeof nuxt_plugin_closepopupstorycard_459b9931 === 'function') {
    await nuxt_plugin_closepopupstorycard_459b9931(app.context, inject)
  }

  if (typeof nuxt_plugin_currentlocale_56392be1 === 'function') {
    await nuxt_plugin_currentlocale_56392be1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_datetimepickerclient_665306aa === 'function') {
    await nuxt_plugin_datetimepickerclient_665306aa(app.context, inject)
  }

  if (typeof nuxt_plugin_defaultcityid_4176f3fa === 'function') {
    await nuxt_plugin_defaultcityid_4176f3fa(app.context, inject)
  }

  if (typeof nuxt_plugin_defaultlocale_308eeaa9 === 'function') {
    await nuxt_plugin_defaultlocale_308eeaa9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_directivesclient_c5c00a00 === 'function') {
    await nuxt_plugin_directivesclient_c5c00a00(app.context, inject)
  }

  if (typeof nuxt_plugin_distance_3c19c804 === 'function') {
    await nuxt_plugin_distance_3c19c804(app.context, inject)
  }

  if (typeof nuxt_plugin_duration_4acbae05 === 'function') {
    await nuxt_plugin_duration_4acbae05(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_editorclient_22772bae === 'function') {
    await nuxt_plugin_editorclient_22772bae(app.context, inject)
  }

  if (typeof nuxt_plugin_entitysingle_4315e399 === 'function') {
    await nuxt_plugin_entitysingle_4315e399(app.context, inject)
  }

  if (typeof nuxt_plugin_galleryframewidth_b8d8cf00 === 'function') {
    await nuxt_plugin_galleryframewidth_b8d8cf00(app.context, inject)
  }

  if (typeof nuxt_plugin_galleryindent_9cdf6230 === 'function') {
    await nuxt_plugin_galleryindent_9cdf6230(app.context, inject)
  }

  if (typeof nuxt_plugin_handlescrollto_3752db7a === 'function') {
    await nuxt_plugin_handlescrollto_3752db7a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_headlinkclient_3f453d07 === 'function') {
    await nuxt_plugin_headlinkclient_3f453d07(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_3c15c38a === 'function') {
    await nuxt_plugin_i18n_3c15c38a(app.context, inject)
  }

  if (typeof nuxt_plugin_instantreplacequery_566452b2 === 'function') {
    await nuxt_plugin_instantreplacequery_566452b2(app.context, inject)
  }

  if (typeof nuxt_plugin_isemail_737b3976 === 'function') {
    await nuxt_plugin_isemail_737b3976(app.context, inject)
  }

  if (typeof nuxt_plugin_isemptyarray_4a8dfb4e === 'function') {
    await nuxt_plugin_isemptyarray_4a8dfb4e(app.context, inject)
  }

  if (typeof nuxt_plugin_isemptyobject_789cdc09 === 'function') {
    await nuxt_plugin_isemptyobject_789cdc09(app.context, inject)
  }

  if (typeof nuxt_plugin_isexternallink_7089b154 === 'function') {
    await nuxt_plugin_isexternallink_7089b154(app.context, inject)
  }

  if (typeof nuxt_plugin_isphoneorempty_e9645074 === 'function') {
    await nuxt_plugin_isphoneorempty_e9645074(app.context, inject)
  }

  if (typeof nuxt_plugin_isphone_0c5fc338 === 'function') {
    await nuxt_plugin_isphone_0c5fc338(app.context, inject)
  }

  if (typeof nuxt_plugin_isrutubevideo_d33894da === 'function') {
    await nuxt_plugin_isrutubevideo_d33894da(app.context, inject)
  }

  if (typeof nuxt_plugin_issite_1c550281 === 'function') {
    await nuxt_plugin_issite_1c550281(app.context, inject)
  }

  if (typeof nuxt_plugin_isvkvideo_deefe936 === 'function') {
    await nuxt_plugin_isvkvideo_deefe936(app.context, inject)
  }

  if (typeof nuxt_plugin_isyoutubevideo_fbc46abe === 'function') {
    await nuxt_plugin_isyoutubevideo_fbc46abe(app.context, inject)
  }

  if (typeof nuxt_plugin_login_97c3df7c === 'function') {
    await nuxt_plugin_login_97c3df7c(app.context, inject)
  }

  if (typeof nuxt_plugin_logout_4b3b07f6 === 'function') {
    await nuxt_plugin_logout_4b3b07f6(app.context, inject)
  }

  if (typeof nuxt_plugin_mapchangeroute_17b4d23c === 'function') {
    await nuxt_plugin_mapchangeroute_17b4d23c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_maskapatternemailclient_27aa0107 === 'function') {
    await nuxt_plugin_maskapatternemailclient_27aa0107(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_maskapatternsiteclient_7281a838 === 'function') {
    await nuxt_plugin_maskapatternsiteclient_7281a838(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_maskapatterntelclient_7d576206 === 'function') {
    await nuxt_plugin_maskapatterntelclient_7d576206(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_maskaclient_4bfa23b5 === 'function') {
    await nuxt_plugin_maskaclient_4bfa23b5(app.context, inject)
  }

  if (typeof nuxt_plugin_mediaswitch_abf9741c === 'function') {
    await nuxt_plugin_mediaswitch_abf9741c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_openpopupaddplaceclient_506b419a === 'function') {
    await nuxt_plugin_openpopupaddplaceclient_506b419a(app.context, inject)
  }

  if (typeof nuxt_plugin_openpopuporder_286f1f25 === 'function') {
    await nuxt_plugin_openpopuporder_286f1f25(app.context, inject)
  }

  if (typeof nuxt_plugin_openpupupforprotectedareas_7117cab6 === 'function') {
    await nuxt_plugin_openpupupforprotectedareas_7117cab6(app.context, inject)
  }

  if (typeof nuxt_plugin_prefixlocale_7bffc836 === 'function') {
    await nuxt_plugin_prefixlocale_7bffc836(app.context, inject)
  }

  if (typeof nuxt_plugin_randomstring_6033f010 === 'function') {
    await nuxt_plugin_randomstring_6033f010(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrolltovalidationclient_693296c7 === 'function') {
    await nuxt_plugin_scrolltovalidationclient_693296c7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrollbarclient_42b52eb4 === 'function') {
    await nuxt_plugin_scrollbarclient_42b52eb4(app.context, inject)
  }

  if (typeof nuxt_plugin_seoimage_7dc25980 === 'function') {
    await nuxt_plugin_seoimage_7dc25980(app.context, inject)
  }

  if (typeof nuxt_plugin_seo_23915d18 === 'function') {
    await nuxt_plugin_seo_23915d18(app.context, inject)
  }

  if (typeof nuxt_plugin_showcityselector_15da65fe === 'function') {
    await nuxt_plugin_showcityselector_15da65fe(app.context, inject)
  }

  if (typeof nuxt_plugin_socials_2091b465 === 'function') {
    await nuxt_plugin_socials_2091b465(app.context, inject)
  }

  if (typeof nuxt_plugin_theme_7da66cac === 'function') {
    await nuxt_plugin_theme_7da66cac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetouchclient_404d7438 === 'function') {
    await nuxt_plugin_vuetouchclient_404d7438(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ymappluginclient_14d0733d === 'function') {
    await nuxt_plugin_ymappluginclient_14d0733d(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
