import { render, staticRenderFns } from "./TopEntityComponentPhone.vue?vue&type=template&id=1eee0710&"
import script from "./TopEntityComponentPhone.vue?vue&type=script&lang=js&"
export * from "./TopEntityComponentPhone.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkPhone: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/LinkPhone/index.vue').default})
