import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=46b7b3b9&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionJournalTopWithImage: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SectionJournalTopWithImage/index.vue').default,SectionJournalTop: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SectionJournalTop/index.vue').default,ArticleSection: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/ArticleSection/index.vue').default,Section: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Section/index.vue').default,SectionAviaKassaWidgetComponent: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SectionAviaKassaWidgetComponent/index.vue').default,SharingComponent: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SharingComponent/index.vue').default,SectionComments: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SectionComments/index.vue').default,SectionBannerAppMobile: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SectionBannerAppMobile/index.vue').default,SectionBillboard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SectionBillboard/index.vue').default})
