import { errors } from '@/utils/errors'
import defaultStore from '@/utils/schedule-default-store-config'
import { scheduleAppend } from '~/utils/schedule-append'
import { scheduleRequest } from '~/utils/schedule-request'

export const state = () => ({
  started_at: null,
  stopped_at: null,
  tariff: '',
  price: 0,
  time: null,
  days: [],
  quota: 0,
  pending: false,
  errors: null,
  edit: false,
  id: null,
  id_tariff: null,
  type: null,
  max_bonus_points: 0
})

export const mutations = {
  setType: (state, type) => state.type = type,
  setId: (state, id) => state.id = id,
  setIdTariff: (state, id_tariff) => state.id_tariff = id_tariff,
  setStartedAt: (state, started_at) => {
    state.started_at = started_at
    state.errors = errors('started_at', state.errors)
  },
  setStoppedAt: (state, stopped_at) => {
    state.stopped_at = stopped_at
    state.errors = errors('stopped_at', state.errors)
  },
  setTariff: (state, tariff) => {
    state.tariff = tariff
    state.errors = errors('tariff_title', state.errors)
  },
  setPrice: (state, price) => {
    state.price = price
    state.errors = errors('price', state.errors)
  },
  setTime: (state, time) => {
    state.time = time
    state.errors = errors('time', state.errors)
  },
  setQuota: (state, quota) => {
    state.quota = quota
    state.errors = errors('quota', state.errors)
  },
  setDays: (state, days) => {
    state.days = days
    state.errors = errors('days', state.errors)
  },
  setErrors: (state, errors) => (state.errors = { ...state.errors, ...errors }),
  setPending: (state, pending) => (state.pending = pending),
  setEdit: (state, edit) => (state.edit = edit),
  setMaxBonusPoints: (state, max_bonus_points) => {
    state.max_bonus_points = max_bonus_points
    state.errors = errors('max_bonus_points', state.errors)
  },
  clear: (state) => {
    for (const key in defaultStore) {
      state[key] = defaultStore[key]
    }
  }
}

export const actions = {
  async post ({
                state,
                commit,
                dispatch
              }) {
    commit('setPending', true)
    const data = scheduleAppend(state)
    await this.$axios
      .$post(scheduleRequest(state), data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        commit('popup/close', null, { root: true })
        if (!state.edit) {
          commit('popup/open', {
            name: 'PopupAddPlaceSuccess',
          }, { root: true })
        }
        commit('setPending', false)
        if (state.edit) {
          commit('clear')
        }
        dispatch(`account-agency-${state.type}/fetch`, {
          id: state.id,
          params: {
            lang: this.$i18n.locale
          }
        }, { root: true })
      })
      .then(() => {
        dispatch(`account-agency-${state.type}/schedules`, {
          id: state.id,
          clear: true,
          params: {
            count: 30
          }
        }, { root: true })
        commit('clear')
      })
      .catch((error) => {
        commit('setErrors', error.response.data)
        commit('setPending', false)
      })
  },
}

export const getters = {
  type: (state) => state.type,
  id: (state) => state.id,
  id_tariff: (state) => state.id_tariff,
  started_at: (state) => state.started_at,
  stopped_at: (state) => state.stopped_at,
  tariff: (state) => state.tariff,
  price: (state) => state.price,
  time: (state) => state.time,
  days: (state) => state.days,
  quota: (state) => state.quota,
  pending: (state) => state.pending,
  errors: (state) => state.errors,
  edit: (state) => state.edit,
  max_bonus_points: (state) => state.max_bonus_points
}
