//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import journal from "~/middleware/pages/journal";

export default {
  name: "JournalView",
  components: {
    SectionAviaKassaWidgetComponent: () => import("@/components/common/SectionAviaKassaWidgetComponent"),
    SectionJournalTopWithImage: () => import("@/components/common/SectionJournalTopWithImage"),
    SectionJournalTop: () => import("@/components/common/SectionJournalTop"),
    SectionComments: () => import("@/components/common/SectionComments"),
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile/index"),
    SectionBillboard: () => import("@/components/common/SectionBillboard/index"),
    ArticleSection: () => import("@/components/common/ArticleSection/index"),
    Section: () => import("@/components/common/Section/index"),
    SharingComponent: () => import("@/components/common/SharingComponent/index")
  },
  middleware: [journal],
  head() {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.title,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.$seoImage(this.images) || this.SEOImage,
          url: this.SEOUrl,
          locale: this.SEOLocale,
          site_name: this.SEOSiteName
        },
        vk: {
          image: this.$seoImage(this.images) || this.SEOImage,
        }
      })
    };
  },
  computed: {
    ...mapGetters({
      SEOType: "seo/type",
      SEOTitle: "seo/title",
      SEOKeywords: "seo/keywords",
      SEODescription: "seo/description",
      SEOImage: "seo/image",
      SEOUrl: "seo/url",
      SEOLocale: "seo/locale",
      SEOSiteName: "seo/siteName",
      journal: "journal/journal"
    }),
    components() {
      return this.$config?.pages?.JournalView?.components;
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile;
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard;
    },
    showSectionComments() {
      return this.components?.SectionComments;
    },
    showSectionAviaKassaWidgetComponent() {
      return this.components?.SectionAviaKassaWidgetComponent;
    },
    id() {
      return this?.journal?.id;
    },
    entity() {
      return this?.journal?.entity;
    },
    title() {
      return this?.journal?.title;
    },
    description() {
      return this?.journal?.description;
    },
    image() {
      return this?.journal?.picture?.src;
    },
    articles() {
      return this?.journal?.blocks;
    },
    status() {
      return this?.journal?.status;
    },
    picture() {
      return this?.journal?.picture;
    },
    showCover() {
      return this?.journal?.show_cover;
    },
    url() {
      return this.$store.state.host + this?.$route?.fullPath;
    },
    mediaSource() {
      return {
        name: this?.journal?.picture?.title,
        link: this?.journal?.picture?.link,
      } || null;
    }
  }
};
