//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import seo from "@/middleware/seo";
import interactiveMap from "@/middleware/pages/interactive-map.js";

export default {
  name: "InteractiveMapView",
  components: {
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    SectionBillboard: () => import("@/components/common/SectionBillboard"),
    Section: () => import("@/components/common/Section/index"),
    MapNavigation: () => import("@/components/common/MapNavigation/MapNavigation"),
    OnMap: () => import("@/components/common/OnMap/index"),
    RoundButton: () => import("@/components/common/RoundButton"),
    Icon24FillArrowLeft: () => import("@/assets/icons/Icon24FillArrowLeft.svg?inline"),
    Icon24FillArrowList: () => import("@/assets/icons/Icon24FillArrowList.svg?inline"),
  },
  middleware: [seo, interactiveMap],
  data() {
    return {
      transform: false
    };
  },
  head() {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t("pages.index.seo.site-name"),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
        }
      })
    };
  },
  computed: {
    ...mapGetters({
      SEOType: "seo/type",
      SEOTitle: "seo/title",
      SEOKeywords: "seo/keywords",
      SEODescription: "seo/description",
      SEOImage: "seo/image",
      SEOUrl: "seo/url",
      SEOLocale: "seo/locale",
      SEOSiteName: "seo/siteName",
      city: "cities/default",
      search: "interactive-map/query",
      city_id: "interactive-map/city_id",
      category_id: "interactive-map/category_id",
      id: "interactive-map/id",
      entity: "interactive-map/entity",
      features: "interactive-map/features",
      featuresCollection: "interactive-map/featuresCollection",
      area: "interactive-map/area"
    }),
    trapaaa() {
      return this.transform ? `100%` : `calc(100% - 604px)`;
    },
    query() {
      return {
        category_id: this.category_id,
        city_id: this.city_id,
        search: this.search,
        entity: this.entity,
        id: this.id,
      };
    },
    zoom() {
      return Number(this.city?.zoom) || this.$config.zoom;
    },
    coords() {
      return [this.city?.latitude, this.city?.longitude] || this.$config.coords;
    },
    components() {
      return this.$config?.pages?.InteractiveMapView?.components;
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile;
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard;
    },
  },
  watch: {
    query(query) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...this.query
        }
      })
        .then(() => {
          if (query.search && !query.id) {
            if (query.entity && query.id) {
              if (query.entity === "places") {
                if (query.id) {
                  this.$store.dispatch("interactive-map/place", {});
                } else {
                  this.$store.dispatch("interactive-map/places", {});
                }
              } else if (query.entity === "routes") {
                if (query.id) {
                  this.$store.dispatch("interactive-map/route", {});
                } else {
                  this.$store.dispatch("interactive-map/routes", {});
                }
              }
            } else {
              this.$store.dispatch("interactive-map/query", query.query);
            }
          } else {
            if (query.entity === "places") {
              if (query.id) {
                this.$store.dispatch("interactive-map/place", {});
              } else {
                this.$store.dispatch("interactive-map/places", {});
              }
            } else if (query.entity === "routes") {
              if (query.id) {
                this.$store.dispatch("interactive-map/route", {});
              } else {
                this.$store.dispatch("interactive-map/routes", {});
              }
            } else if (query.entity === "categories") {
              if (query.category_id) {
                this.$store.dispatch("interactive-map/places", {});
              }
              // this.$store.dispatch('interactive-map/places', {})
            } else if (query.entity === "layers") {
              if (query.id) {
                this.$store.dispatch("interactive-map/layer", {});
                this.$store.dispatch("interactive-map/areas", {});
                this.$store.dispatch("interactive-map/area", {});
              } else {
                this.$store.dispatch("interactive-map/layers", {});
              }
            }
          }
        })
        .catch(() => {
        });
    },
  },
  created() {
    const query = this.$route.query;
    this.$store.commit("interactive-map/setLang", this.$i18n.locale);
    if (query.entity) {
      this.$store.commit("interactive-map/setEntity", query.entity);
    } else {
      this.$store.commit("interactive-map/setEntity", "places");
    }
    if (this.$config.singleCity) {
      this.$store.commit("interactive-map/setCityId", this.city.id);
    } else {
      if (query.city_id) {
        this.$store.commit("interactive-map/setCityId", Number(query.city_id));
      } else {
        this.$store.commit("interactive-map/setCityId", null);
      }
    }
    if (query.id) {
      this.$store.commit("interactive-map/setId", Number(query.id));
    } else {
      this.$store.commit("interactive-map/setId", null);
    }
    if (query.search) {
      this.$store.commit("interactive-map/setSearch", query.search);
    } else {
      this.$store.commit("interactive-map/setSearch", null);
    }
    if (query.category_id) {
      this.$store.commit("interactive-map/setCategoryId", Number(query.category_id));
    } else {
      this.$store.commit("interactive-map/setCategoryId", null);
    }
    this.$router.replace({query: {...this.$route.query, ...this.query}})
      .catch(() => {
      });
    this.$store.commit("footer/setShow", false);
  },
  mounted() {
    const query = this.$route.query;
    if (query.search && !query.id) {
      this.$store.dispatch("interactive-map/query", query.query);
    } else {
      if (query.entity === "places") {
        if (query.id) {
          this.$store.dispatch("interactive-map/place", {});
        } else {
          this.$store.dispatch("interactive-map/places", {});
        }
      } else if (query.entity === "routes") {
        if (query.id) {
          this.$store.dispatch("interactive-map/route", {});
        } else {
          this.$store.dispatch("interactive-map/routes", {});
        }
      } else if (query.entity === "categories") {
        if (query.category_id) {
          this.$store.dispatch("interactive-map/places", {});
        }
        // this.$store.dispatch('interactive-map/places', {})
      } else if (query.entity === "layers") {
        if (query.id) {
          this.$store.dispatch("interactive-map/layer", {});
          this.$store.dispatch("interactive-map/areas", {});
          this.$store.dispatch("interactive-map/area", {});
        } else {
          this.$store.dispatch("interactive-map/layers", {});
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("footer/setShow", true);
  },
  methods: {
    showHide() {
      this.transform = !this.transform;
      setTimeout(() => {
        document.getElementById("tourism-zoom-map-buttons").classList.toggle("on-map__zoom-buttons--big");
      }, 0)

    }
  }
};
