import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=2abca5d2&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopTitleComponent: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/TopTitleComponent/index.vue').default,Section: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Section/index.vue').default,TopEntityComponent: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/TopEntityComponent/index.vue').default,ContextAdditions: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/ContextAdditions/index.vue').default,SocialVideoFrameComponent: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SocialVideoFrameComponent/index.vue').default,Brochure: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Brochure/index.vue').default,EntityContent: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/EntityContent/index.vue').default,Gallery: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Gallery/index.vue').default,SectionSharing: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SectionSharing/index.vue').default,SectionBannerAppMobile: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SectionBannerAppMobile/index.vue').default,SectionBillboard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/SectionBillboard/index.vue').default})
