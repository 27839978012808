import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=1911b506&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"
import style0 from "./_id.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopupStoryCard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/PopupStory/PopupStoryCard.vue').default})
