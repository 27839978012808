import { render, staticRenderFns } from "./_event_id.vue?vue&type=template&id=58970473&"
import script from "./_event_id.vue?vue&type=script&lang=js&"
export * from "./_event_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Status: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Status/index.vue').default,Gallery: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Gallery/index.vue').default,ContextAdditions: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/ContextAdditions/index.vue').default,Section: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Section/index.vue').default,AgencyEventPlaces: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/AgencyEventPlaces/index.vue').default,AgencyEventSchedules: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/AgencyEventSchedules/index.vue').default})
