import { render, staticRenderFns } from "./MapNavigationRoute.vue?vue&type=template&id=1be330d9&"
import script from "./MapNavigationRoute.vue?vue&type=script&lang=js&"
export * from "./MapNavigationRoute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapNavigationBackButton: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/MapNavigation/MapNavigationBackButton.vue').default,Favorite: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Favorite/index.vue').default,ImageComponent: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/ImageComponent/index.vue').default,TopEntityRating: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/TopEntityRating/index.vue').default,Divider: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Divider/index.vue').default,Duration: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Duration/index.vue').default,Distance: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Distance/index.vue').default,HorizontalEntityCard: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/HorizontalEntityCard/index.vue').default,Button: require('/var/www/visit-front-chuvashia.ru/releases/16/components/common/Button/index.vue').default})
