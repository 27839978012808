import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/account-agency-event.js'), 'account-agency-event.js')
  resolveStoreModules(require('../store/account-agency-events.js'), 'account-agency-events.js')
  resolveStoreModules(require('../store/account-agency-excursion.js'), 'account-agency-excursion.js')
  resolveStoreModules(require('../store/account-agency-excursions.js'), 'account-agency-excursions.js')
  resolveStoreModules(require('../store/account-agency-orders-view.js'), 'account-agency-orders-view.js')
  resolveStoreModules(require('../store/account-agency-orders.js'), 'account-agency-orders.js')
  resolveStoreModules(require('../store/account-agency-places.js'), 'account-agency-places.js')
  resolveStoreModules(require('../store/account-agency-profile.js'), 'account-agency-profile.js')
  resolveStoreModules(require('../store/add-event.js'), 'add-event.js')
  resolveStoreModules(require('../store/add-excursion.js'), 'add-excursion.js')
  resolveStoreModules(require('../store/add-feedback.js'), 'add-feedback.js')
  resolveStoreModules(require('../store/add-photos.js'), 'add-photos.js')
  resolveStoreModules(require('../store/add-place.js'), 'add-place.js')
  resolveStoreModules(require('../store/add-route.js'), 'add-route.js')
  resolveStoreModules(require('../store/add-schedule.js'), 'add-schedule.js')
  resolveStoreModules(require('../store/add-travel.js'), 'add-travel.js')
  resolveStoreModules(require('../store/add-widget.js'), 'add-widget.js')
  resolveStoreModules(require('../store/age-restrictions.js'), 'age-restrictions.js')
  resolveStoreModules(require('../store/agencies.js'), 'agencies.js')
  resolveStoreModules(require('../store/agency-branches.js'), 'agency-branches.js')
  resolveStoreModules(require('../store/agency.js'), 'agency.js')
  resolveStoreModules(require('../store/article.js'), 'article.js')
  resolveStoreModules(require('../store/articles.js'), 'articles.js')
  resolveStoreModules(require('../store/audio-player.js'), 'audio-player.js')
  resolveStoreModules(require('../store/categories.js'), 'categories.js')
  resolveStoreModules(require('../store/cities.js'), 'cities.js')
  resolveStoreModules(require('../store/code.js'), 'code.js')
  resolveStoreModules(require('../store/comments.js'), 'comments.js')
  resolveStoreModules(require('../store/edit-agency.js'), 'edit-agency.js')
  resolveStoreModules(require('../store/event.js'), 'event.js')
  resolveStoreModules(require('../store/events.js'), 'events.js')
  resolveStoreModules(require('../store/excursion.js'), 'excursion.js')
  resolveStoreModules(require('../store/excursions.js'), 'excursions.js')
  resolveStoreModules(require('../store/favorites.js'), 'favorites.js')
  resolveStoreModules(require('../store/footer.js'), 'footer.js')
  resolveStoreModules(require('../store/galleries.js'), 'galleries.js')
  resolveStoreModules(require('../store/gallery.js'), 'gallery.js')
  resolveStoreModules(require('../store/guide.js'), 'guide.js')
  resolveStoreModules(require('../store/guides.js'), 'guides.js')
  resolveStoreModules(require('../store/header-navigation.js'), 'header-navigation.js')
  resolveStoreModules(require('../store/hero-slider.js'), 'hero-slider.js')
  resolveStoreModules(require('../store/interactive-map.js'), 'interactive-map.js')
  resolveStoreModules(require('../store/journal.js'), 'journal.js')
  resolveStoreModules(require('../store/journals.js'), 'journals.js')
  resolveStoreModules(require('../store/languages.js'), 'languages.js')
  resolveStoreModules(require('../store/layout-personal-area-agency.js'), 'layout-personal-area-agency.js')
  resolveStoreModules(require('../store/levels.js'), 'levels.js')
  resolveStoreModules(require('../store/map.js'), 'map.js')
  resolveStoreModules(require('../store/mobile-menu.js'), 'mobile-menu.js')
  resolveStoreModules(require('../store/municipality.js'), 'municipality.js')
  resolveStoreModules(require('../store/news.js'), 'news.js')
  resolveStoreModules(require('../store/order-for-protected-area.js'), 'order-for-protected-area.js')
  resolveStoreModules(require('../store/order.js'), 'order.js')
  resolveStoreModules(require('../store/photos.js'), 'photos.js')
  resolveStoreModules(require('../store/piece-news.js'), 'piece-news.js')
  resolveStoreModules(require('../store/place.js'), 'place.js')
  resolveStoreModules(require('../store/places.js'), 'places.js')
  resolveStoreModules(require('../store/popup.js'), 'popup.js')
  resolveStoreModules(require('../store/route.js'), 'route.js')
  resolveStoreModules(require('../store/routes.js'), 'routes.js')
  resolveStoreModules(require('../store/schedule-calendar.js'), 'schedule-calendar.js')
  resolveStoreModules(require('../store/search-place-drop-down-menu.js'), 'search-place-drop-down-menu.js')
  resolveStoreModules(require('../store/search.js'), 'search.js')
  resolveStoreModules(require('../store/seasons.js'), 'seasons.js')
  resolveStoreModules(require('../store/seo.js'), 'seo.js')
  resolveStoreModules(require('../store/stories.js'), 'stories.js')
  resolveStoreModules(require('../store/story.js'), 'story.js')
  resolveStoreModules(require('../store/terms.js'), 'terms.js')
  resolveStoreModules(require('../store/theme.js'), 'theme.js')
  resolveStoreModules(require('../store/toasters.js'), 'toasters.js')
  resolveStoreModules(require('../store/tourism-types.js'), 'tourism-types.js')
  resolveStoreModules(require('../store/travel.js'), 'travel.js')
  resolveStoreModules(require('../store/travels.js'), 'travels.js')
  resolveStoreModules(require('../store/user.js'), 'user.js')
  resolveStoreModules(require('../store/viewport.js'), 'viewport.js')
  resolveStoreModules(require('../store/widget.js'), 'widget.js')
  resolveStoreModules(require('../store/widgets.js'), 'widgets.js')
  resolveStoreModules(require('../store/pages/category-collections.js'), 'pages/category-collections.js')
  resolveStoreModules(require('../store/pages/events.js'), 'pages/events.js')
  resolveStoreModules(require('../store/pages/excursions.js'), 'pages/excursions.js')
  resolveStoreModules(require('../store/pages/organization.js'), 'pages/organization.js')
  resolveStoreModules(require('../store/pages/organizations.js'), 'pages/organizations.js')
  resolveStoreModules(require('../store/pages/places.js'), 'pages/places.js')
  resolveStoreModules(require('../store/pages/routes.js'), 'pages/routes.js')
  resolveStoreModules(require('../store/pages/travel-agencies.js'), 'pages/travel-agencies.js')
  resolveStoreModules(require('../store/pages/travel-agency.js'), 'pages/travel-agency.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
